/** @jsx jsx */
import {
  jsx,
  useColorMode,
  Image,
  Box,
  Button,
  Flex,
  Text,
  Link,
} from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import { Helmet } from 'react-helmet';
import Sun from './sun.svg';
import Moon from './moon.svg';

const style = {
  maxWidth: '70ch',
  padding: '2ch',
  paddingTop: [3, 4],
  margin: 'auto',
  backgroundColor: 'background',
};

export default function Layout({ children }) {
  const [colorMode, setColorMode] = useColorMode();

  return (
    <div className="container" sx={style}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Njus</title>
        <meta
          name="description"
          content="Senaste 2 dagarnas nyheter från Njus"
        />
        <link rel="shortcut icon" href="/favicon.ico"></link>
      </Helmet>
      <header>
        <Flex
          mb={[3, 4]}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text as="h1" variant="pageTitle">
            <Link to="/" as={GatsbyLink} variant="pageTitle">
              Njus
            </Link>
          </Text>
          <Button
            variant="link"
            onClick={(e) => {
              setColorMode(colorMode === 'default' ? 'dark' : 'default');
            }}
          >
            <Image src={colorMode === 'default' ? Moon : Sun} variant="logo" />
          </Button>
        </Flex>
      </header>
      <Box as="main" mb={3}>
        {children}
      </Box>
    </div>
  );
}
