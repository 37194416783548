/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Link } from 'gatsby';

export default function ({ href, title, sx }) {
  return (
    <Styled.a to={href} as={Link} sx={sx} rel="noreferrer">
      {title}
    </Styled.a>
  );
}
